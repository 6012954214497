var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcTextArea from 'rc-textarea';
import omit from 'rc-util/lib/omit';
import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import ClearableLabeledInput from './ClearableLabeledInput';
import { ConfigContext } from '../config-provider';
import { fixControlledValue, resolveOnChange, triggerFocus } from './Input';
import SizeContext from '../config-provider/SizeContext';
function fixEmojiLength(value, maxLength) {
    return [...(value || '')].slice(0, maxLength).join('');
}
const TextArea = React.forwardRef((_a, ref) => {
    var { prefixCls: customizePrefixCls, bordered = true, showCount = false, maxLength, className, style, size: customizeSize, onCompositionStart, onCompositionEnd, onChange } = _a, props = __rest(_a, ["prefixCls", "bordered", "showCount", "maxLength", "className", "style", "size", "onCompositionStart", "onCompositionEnd", "onChange"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    const innerRef = React.useRef(null);
    const clearableInputRef = React.useRef(null);
    const [compositing, setCompositing] = React.useState(false);
    const [value, setValue] = useMergedState(props.defaultValue, {
        value: props.value,
    });
    const handleSetValue = (val, callback) => {
        if (props.value === undefined) {
            setValue(val);
            callback === null || callback === void 0 ? void 0 : callback();
        }
    };
    // =========================== Value Update ===========================
    // Max length value
    const hasMaxLength = Number(maxLength) > 0;
    const onInternalCompositionStart = e => {
        setCompositing(true);
        onCompositionStart === null || onCompositionStart === void 0 ? void 0 : onCompositionStart(e);
    };
    const onInternalCompositionEnd = e => {
        setCompositing(false);
        let triggerValue = e.currentTarget.value;
        if (hasMaxLength) {
            triggerValue = fixEmojiLength(triggerValue, maxLength);
        }
        // Patch composition onChange when value changed
        if (triggerValue !== value) {
            handleSetValue(triggerValue);
            resolveOnChange(e.currentTarget, e, onChange, triggerValue);
        }
        onCompositionEnd === null || onCompositionEnd === void 0 ? void 0 : onCompositionEnd(e);
    };
    const handleChange = (e) => {
        let triggerValue = e.target.value;
        if (!compositing && hasMaxLength) {
            triggerValue = fixEmojiLength(triggerValue, maxLength);
        }
        handleSetValue(triggerValue);
        resolveOnChange(e.currentTarget, e, onChange, triggerValue);
    };
    // ============================== Reset ===============================
    const handleReset = (e) => {
        var _a, _b;
        handleSetValue('', () => {
            var _a;
            (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        });
        resolveOnChange((_b = (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea) === null || _b === void 0 ? void 0 : _b.textArea, e, onChange);
    };
    const prefixCls = getPrefixCls('input', customizePrefixCls);
    React.useImperativeHandle(ref, () => {
        var _a;
        return ({
            resizableTextArea: (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea,
            focus: (option) => {
                var _a, _b;
                triggerFocus((_b = (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea) === null || _b === void 0 ? void 0 : _b.textArea, option);
            },
            blur: () => { var _a; return (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.blur(); },
        });
    });
    const textArea = (<RcTextArea {...omit(props, ['allowClear'])} className={classNames({
            [`${prefixCls}-borderless`]: !bordered,
            [className]: className && !showCount,
            [`${prefixCls}-sm`]: size === 'small' || customizeSize === 'small',
            [`${prefixCls}-lg`]: size === 'large' || customizeSize === 'large',
        })} style={showCount ? undefined : style} prefixCls={prefixCls} onCompositionStart={onInternalCompositionStart} onChange={handleChange} onCompositionEnd={onInternalCompositionEnd} ref={innerRef}/>);
    let val = fixControlledValue(value);
    if (!compositing && hasMaxLength && (props.value === null || props.value === undefined)) {
        // fix #27612 将value转为数组进行截取，解决 '😂'.length === 2 等emoji表情导致的截取乱码的问题
        val = fixEmojiLength(val, maxLength);
    }
    // TextArea
    const textareaNode = (<ClearableLabeledInput {...props} prefixCls={prefixCls} direction={direction} inputType="text" value={val} element={textArea} handleReset={handleReset} ref={clearableInputRef} bordered={bordered}/>);
    // Only show text area wrapper when needed
    if (showCount) {
        const valueLength = [...val].length;
        let dataCount = '';
        if (typeof showCount === 'object') {
            dataCount = showCount.formatter({ count: valueLength, maxLength });
        }
        else {
            dataCount = `${valueLength}${hasMaxLength ? ` / ${maxLength}` : ''}`;
        }
        return (<div className={classNames(`${prefixCls}-textarea`, {
                [`${prefixCls}-textarea-rtl`]: direction === 'rtl',
            }, `${prefixCls}-textarea-show-count`, className)} style={style} data-count={dataCount}>
          {textareaNode}
        </div>);
    }
    return textareaNode;
});
export default TextArea;
